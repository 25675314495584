import { Line, type LineOptions } from '@antv/g2plot';
import { useDeepCompareEffect } from 'ahooks';
import { Button } from 'antd';
import Big from 'big.js';
import { memo, useRef } from 'react';
import { useGlobalMyInfo } from '../myInfo/useGlobalMyInfo';

interface propType extends LineOptions {
	height: number;
}

export const LineComponent = memo((props: propType) => {
	const container = useRef(null);
	const ins = useRef<Line | null>(null);

	const [stateParams] = useGlobalMyInfo();
	const isShowLabel =
		stateParams?.globalSensitiveInformationHiding === false ?? true;

	const shouldData = props?.data?.map((a) => {
		a.value = a.value?.toNumber ? a.value.toNumber() : a.value;

		return a;
	});

	useDeepCompareEffect(() => {
		if (!shouldData) {
			return;
		}

		if (ins.current) {
			ins.current?.changeData(shouldData);

			// ins.current.render();
			return;
		}

		if (!container.current) {
			return;
		}

		const l = new Line(container.current, {
			...props,
			data: shouldData,
			label: {
				// 可手动配置 label 数据标签位置
				position: 'top', // 'top', 'bottom', 'middle',
				// 配置样式
				style: {
					fill: '#000000',
					opacity: isShowLabel ? 1 : 0,
				},
			},
			point: {
				size: 5,
				shape: 'diamond',
				style: {
					fill: 'white',
					stroke: '#5B8FF9',
					lineWidth: 2,
				},
			},
			state: {
				active: {
					style: {
						shadowBlur: 4,
						stroke: '#000',
						fill: 'red',
					},
				},
			},
			tooltip: { showMarkers: false },
			interactions: [{ type: 'marker-active' }],
		});

		ins.current = l;
		l.render();
	}, [props.data]);

	return (
		<>
			<div
				style={{
					height: props.height,
					width: '100%',
				}}
				ref={container}
			/>

			{/* <Button
				onClick={() => {
					downloadImage(ins.current?.chart);
				}}
			>
				下载图片
			</Button> */}
		</>
	);
});

/**
 * 返回图表的 dataURL 用于生成图片。
 * @param chart 需要获取 DataURL 的 chart 实例
 * @returns 返回图表的 dataURL
 */
function toDataURL(chart: Chart) {
	const canvas = chart.getCanvas();
	const renderer = chart.renderer;
	const canvasDom = canvas.get('el');
	let dataURL = '';
	if (renderer === 'svg') {
		const clone = canvasDom.cloneNode(true);
		const svgDocType = document.implementation.createDocumentType(
			'svg',
			'-//W3C//DTD SVG 1.1//EN',
			'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd',
		);
		const svgDoc = document.implementation.createDocument(
			'http://www.w3.org/2000/svg',
			'svg',
			svgDocType,
		);
		svgDoc.replaceChild(clone, svgDoc.documentElement);
		const svgData = new XMLSerializer().serializeToString(svgDoc);
		dataURL = `data:image/svg+xml;charset=utf8,${encodeURIComponent(svgData)}`;
	} else if (renderer === 'canvas') {
		dataURL = canvasDom.toDataURL('image/png');
	}
	return dataURL;
}

/**
 * 图表图片导出
 * @param chart chart 实例
 * @param name 图片名称，可选，默认名为 'G2Chart'
 */
function downloadImage(chart: Chart, name: string = 'G2Chart') {
	const link = document.createElement('a');
	const renderer = chart.renderer;
	const filename = `${name}${renderer === 'svg' ? '.svg' : '.png'}`;
	const canvas = chart.getCanvas();
	canvas.get('timeline').stopAllAnimations();

	setTimeout(() => {
		const dataURL = toDataURL(chart);
		if (window.Blob && window.URL && renderer !== 'svg') {
			const arr = dataURL.split(',');
			const mime = arr[0].match(/:(.*?);/)[1];
			const bstr = atob(arr[1]);
			let n = bstr.length;
			const u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			const blobObj = new Blob([u8arr], { type: mime });
			if (window.navigator.msSaveBlob) {
				window.navigator.msSaveBlob(blobObj, filename);
			} else {
				link.addEventListener('click', () => {
					link.download = filename;
					link.href = window.URL.createObjectURL(blobObj);
				});
			}
		} else {
			link.addEventListener('click', () => {
				link.download = filename;
				link.href = dataURL;
			});
		}
		const e = document.createEvent('MouseEvents');
		e.initEvent('click', false, false);
		link.dispatchEvent(e);
	}, 16);
}
